<template>
  <div id="logincomponent">
    <MetaboliteBrowseCompnentSearch :headT='head'></MetaboliteBrowseCompnentSearch>
    <div style="min-height:575px">
      <el-row>
        <el-col :span="8"></el-col>
        <el-col :span="8">
          <div class="grid-content">
            <div class="slider-demo-block" style="width:400px">
              <h4>Account</h4>
              <el-input v-model="item.email" placeholder="Please input user name or email">
              </el-input>
              <h4>Password</h4>

              <el-input v-model="item.password" placeholder="Please input password" :show-password="true">
              </el-input>

              <el-button type="primary" :icon="User" :loading="loading" @click="login"
                style="margin: 30px 0px 15px 0px">login
                <User style="width:12; height:12" />
              </el-button>
              <el-button type="primary" :icon="Edit" style="margin: 30px 20px 15px 20px" @click="change_password">change
                password</el-button>
            </div>
          </div>
        </el-col>
        <el-col :span="8"></el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
  import request from '@/network/request'
  import MetaboliteBrowseCompnentSearch from "@/components/Browse/MetaboliteBrowseCompnentSearch.vue"
  import { Edit, Upload, Picture, Search, UploadFilled, Histogram, User } from '@element-plus/icons-vue'
  import { ElMessage } from 'element-plus'
  export default {
    name: 'LoginComponent',
    data () {
      return {
        head: "",
        item: {},
        loginResponse: ""
      }
    },
    methods: {
      login () {
        var query_params = { "username": this.item.email, "password": this.item.password }
        request({
          url: 'token/',
          data: query_params,
          method: 'post'
        }).then(res => {
          this.loginResponse = res

          this.$store.commit('changeRefresh', res.refresh)
          this.$store.commit('changeAccess', res.access)
          this.$store.commit("changeUsername", query_params.username)
          window.localStorage.setItem("cmhub_access", res.access)
          window.localStorage.setItem("cmhub_refresh", res.refresh)
          window.localStorage.setItem("cmhub_username", res.username)

          this.$router.push({ name: 'User' })
        }).catch(err => {
          console.log(err);
          ElMessage({
            message: 'Login unsuccessfull !',
            type: 'error',
          })
        });
      },
      change_password () {
        this.$router.push({ name: 'ChangePassword' })
      }
    },
    components: {
      MetaboliteBrowseCompnentSearch,
      Edit,
      Upload,
      Picture,
      Search,
      UploadFilled,
      Histogram,
      User,
    },
    created () {
      this.$store.commit("changeUsername", "")
      this.$store.commit("changeRefresh", "")
      this.$store.commit("changeAccess", "")
    },
    mounted () {
      window.vue = this
    },
    updated () {

    },

    watch: {
    }
  }
</script>

<style scoped lang="scss">
  .el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
    /*    max-height: 1000px;
    overflow-y: scroll;*/
  }

  .el-select .el-input {
    width: 130px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .el-table {
    cursor: pointer;
  }
</style>